.giftCard-global-container {
  width: 100%;
  padding-right: 0px;
  padding-left: 0px;
  margin-right: auto;
  margin-left: auto;
  max-width: 808px;
  height: auto;

  .giftcard-banner-container {
    height: 612px;
    width: 100%;
    @media #{$breakpoint-below-tablet} {
      height: 340px;
      width: 360px;
    }
    .banner-headline {
      font-family: $National2Condensed;
      font-weight: 700;
      font-size: 40px;
      line-height: 40px;
      letter-spacing: 1px;
      padding-top: 80px;
      @media #{$breakpoint-below-tablet} {
        padding-top: 48px;
        font-size: 32px;
        line-height: 32px;
        padding-left: 15px;
      }
    }
    .banner-image {
      padding-left: 75px;
      padding-top: 64px;
      @media #{$breakpoint-below-tablet} {
        padding-left: 5%;
        padding-top: 48px;
      }
    }
  }
  .giftcard-already-have-card-container {
    height: 200px;
    //background: #f8f7f5;
    border-bottom: 1px solid rgb(219, 219, 219);
    border-top: 1px solid rgb(219, 219, 219);
    margin-bottom: 78px;
    text-align: center;
    padding: 40px 0px 40px 0px;
    gap: 24px;
    @media #{$breakpoint-below-tablet} {
      margin-bottom: 38px;
      width: 92%;
      margin-left: 16px;
      margin-right: -1px;
    }
    .giftcard-headline {
      letter-spacing: 0.5px;
      font-family: $National2Condensed;
      font-style: normal;
      font-weight: 700px;
      font-size: 24px;
      line-height: 24px;
      width: 100%;
      @media #{$breakpoint-below-tablet} {
        font-size: 20px;
        line-height: 20px;
      }
    }
    .giftcard-description {
      font-family: $National2Regular;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: -0.1px;
      color: grey;
      @media #{$breakpoint-below-tablet} {
        letter-spacing: 0.5px;
        padding-left: 66px;
        padding-right: 66px;
        margin-bottom: 10px;
      }
    }

    .resend-card-button-container {
      padding: 0px 40px 0px 40px;
      padding-top: 10px;
    }
    .resendcard-details-safari{
      @media #{$breakpoint-below-tablet} {
        padding-left: 0;
        margin-left: 0;
      }
    }
    .resendcard-details {
      position: relative;
      //background: #f8f7f5;
    }

    .resendcard-details:hover {
      background: #202124;
      color: #f8f7f5;
    }

    button.resendcard-details {
      &:focus-visible {
        color: #202124;

        &:hover {
          color: #f8f7f5;
        }
      }
    }
  }

  .yourInfoFormContainer {
    height: 260px;
    justify-content: center;
    width: 100%;
    padding: 24px, 40px, 40px, 40px;
    gap: 24px;
    background-color: #f8f7f5;
    @media #{$breakpoint-below-tablet} {
      width: 94%;
      margin-left: 10px;
      margin-right: 10px;
      height: 280px;
    }
    .mt-4 {
      @media #{$breakpoint-below-tablet} {
        margin-top: 0px !important;
      }
    }
    .row {
      margin-left: 0px;
      margin-right: 0px;
    }
    .contactInfoContent {
      @media #{$breakpoint-below-tablet} {
        padding-left: 0px;
      }
    }
    .labelContent-addHope {
      font-family: $National2Condensed;
      font-style: normal;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #202124;
      font-size: 24px;
      line-height: 24px;
      font-weight: 700;
      @media #{$breakpoint-below-tablet} {
        font-size: 20px;
        line-height: 20px;
      }
    }
    .info-label {
      padding-right: 0;
      margin-top: 40px;
      margin-left: 25px;
      padding-left: 0px;
      @media #{$breakpoint-below-tablet} {
        margin-top: 24px;
        margin-left: 0px;
        padding-left: 16px;
      }
    }
    .donate-form-details {
      margin-bottom: -36px;
      padding-right: 40px;
      @media #{$breakpoint-below-tablet} {
        padding-right: 5px;
        padding-left: 0px
      }
    }
    .editSection{
      top: 8%;
    }
    .material-input.field {
      padding-top: 15px;
      padding-left: 0px;
      width: 95%;
      @media #{$breakpoint-below-tablet} {
        width: 107%;
      }
    }
  }

  .recipientForm {
    min-height: 524px;
    justify-content: center;
    width: 100%;
    padding: 24px, 40px, 40px, 40px;
    gap: 24px;
    background-color: #f8f7f5;
    @media #{$breakpoint-below-tablet} {
      width: 94%;
      margin-left: 10px;
      margin-right: 10px;
    }
    .row {
      margin-left: 0px;
      margin-right: 0px;
    }
    .contactInfoContent {
      @media #{$breakpoint-below-tablet} {
        padding-left: 0px;
        padding-right: 0px;
      }
      #user-input {
        padding-left: 10px;
      }
    }
    .labelContent-addHope {
      font-family: $National2Condensed;
      font-style: normal;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #202124;
      font-size: 24px;
      line-height: 24px;
      font-weight: 700;
      @media #{$breakpoint-below-tablet} {
        font-size: 20px;
        line-height: 20px;
      }
    }
    .info-label-recipientForm {
      padding-right: 0;
      margin-top: 40px;
      margin-left: 25px;
      padding-left: 0px;
      @media #{$breakpoint-below-tablet} {
        margin-top: 24px;
        margin-left: 0px;
        padding-left: 24px;
      }
    }
    .donate-form-details {
      margin-bottom: -0px;
      padding-right: 40px;
      padding-bottom: 20px;
      @media #{$breakpoint-below-tablet} {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
    .removeSection{
      padding-top:9%;
      padding-right: 6%;
      justify-content: right;
     @media #{$breakpoint-below-tablet} {
      padding-top:0px;
      margin-top: -25px;
    }
    cursor: pointer;
    text-decoration: underline;
    font-weight: 600;
    }
    .txt-align-center{
      text-align:left !important;
      margin-left: 4px !important;
      @media #{$breakpoint-below-tablet} {
        text-align: center !important;
      }
    }
    .currency-field{
      top: 22px;
      font-size: 16px;
      color: black;
    }
    .cleariconsection {
      position: absolute;
      cursor: pointer;
      @media #{$breakpoint-below-tablet} {
        padding-right: 4%;
      }
    }

    .material-input.field {
      margin-left: 10px;
      width: 95%;
      position: relative;
      @media #{$breakpoint-below-tablet} {
        width: 100%;
        padding-left: 0px !important;
        padding-right: 10px;
        margin-bottom: 50px;
        padding-top: 15px;
      }
    }
    div#user-input {
      @media #{$breakpoint-below-tablet} {
      margin-top: -41px;
      margin-left: 9px;
      margin-bottom: 10px;}
  }
    input[placeholder="Gift card amount"]{
      padding-left: 15px;
    }
    .button-contained[data-type='secondary'] {
      border-radius: 22px;
      font-weight: 600;
      width: auto;
      margin-bottom: 20px;
      margin-top: 20px;
      @media #{$mobile-only} {
        width: 60% !important;
        padding-left: 0px;
        padding-right: 0px;
        height: 40px;
        margin-left: 20%;
        padding-top: 7px;
      }
    }
    .info-label {
      padding-right: 0;
      margin-top: 40px;
      margin-left: 25px;
      padding-left: 0;
      display:none;
    }
    .tipping-details {
      margin-bottom: 22px;
      padding-left: 0px;
      max-width: 100%;
      flex-basis: 100%;
      padding-right: 0px;
      margin-top: 50px;
      @media #{$breakpoint-below-tablet} {
        padding-right: 0px;
        padding-left: 0px !important;
        margin-top: 6px !important
      }
      .button-contained[data-type='primary'] {
        border-radius: 22px;
        font-weight: 600;
        @media #{$mobile-only} {
          width: 60% !important;
          padding-left: 0px;
          padding-right: 0px;
          margin-top: 10px !important;
          height: 40px;
        }
      }
      .drivertip-button-disabled {
        color: #626365 !important;
    }
      .deliveryinputfield{
        margin-bottom: -10px ;
        margin-left: 0px;
        margin-right: 3px;
      }
    }
    .formElement-error{
      border-bottom: 2px solid red !important;
    }
    .flex-container > div {
      width: 117px !important;
      padding-top: 18px;
      height: 56px;
      color: black !important;
      font-family: $National2Regular;
      font-size: 16px;
      line-height: 24px;
      @media #{$breakpoint-below-tablet} {
        width: 27% !important;
      }
    }
    .mt-4 {
      @media #{$breakpoint-below-tablet} {
        margin-top: -30px !important;
      }
    }
  }

  .main-container {
    width: 780px;
    margin-left: auto;
    margin-right: auto;

    .buy-card-text {
      top: 29%;
      font-family: $National2Condensed;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 24px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #202124;
      padding-bottom: 10px;
      border-bottom: 2px solid #202124;
    }

    .card-image {
      margin: 70px 0;
      height: 348px;
      width: 100%;
    }

    .container-heading {
      font-family: $National2Condensed;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #202124;
    }

    .card-amount-container {
      background: #f8f7f5;
      height: 112px;
      display: flex;

      .card-amount-heading {
        padding: 44px 0 0 60px;
      }

      .amount-list {
        margin-left: 85px;
        display: flex;
        margin-top: 42px;

        .amount-block {
          background-color: #f8f7f5;
          font-family: $National2Medium;
          font-size: 13px;
          line-height: 22px;
          padding: 5px;
          height: 32px;
          width: 58px;
          border-radius: 112px;
          border: solid 1px #202124;
          text-align: center;
          margin-right: 10px;
        }

        .amount-block-select {
          color: #f8f7f5;
          background: #202124;
        }
      }
    }

    .infomation-container {
      height: 329px;
      display: flex;
      background: #f8f7f5;
      margin-top: 10px;

      .information-heading {
        padding: 44px 0 260px 60px;
      }

      .error-text {
        color: #ff2e00;
        font-family: $National2Regular;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
      }

      .infomation-input-container {
        margin-left: 83px;
        width: 488px;
        padding-top: 40px;

        .info-input {
          background: #f8f7f5;
          padding-bottom: 8px;
        }

        .mandatory-note {
          font-family: $National2Regular;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 12px;
          letter-spacing: 0px;
          text-align: right;
          color: #494949;
          margin: 0px;
          padding-top: 12px;
        }

        .info-label {
          color: #202124;
        }

        .field {
          margin: 0 !important;
        }
      }
    }

    .msg-container {
      height: 135px;
      display: flex;
      background: #f8f7f5;
      margin-top: 10px;

      .msg-inputbox {
        margin-left: 113px;
        font-family: $National2Regular;
        font-size: 14px;
        border: solid 1px #202124;
        height: 55px;
        width: 488px;
        margin-top: 40px;
        background: #f8f7f5;
      }

      .msg-heading {
        padding: 44px 0 260px 60px;
      }

      ::placeholder {
        padding: 15px;
      }
    }
  }
  #terms-condition-button-container {
    display: flex;
    flex-direction: column-reverse;
  }
  .button-container {
    margin: 50px 160px 0 160px;
    width: 460px;
    margin-left: auto;
    margin-right: auto;

    .cancel-button {
      width: 210px;
      float: left;
    }

    .payment-button {
      float: right;
    }
  }

  .terms-condition-container {
    width: 980px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 45px;
    margin-bottom: 60px;

    .terms-condition-data {
      font-family: $National2TestRegular;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 22px;
      color: #494949;
    }

    .terms-condition-title {
      font-family: $National2TestMedium;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 22px;
      color: #494949;
    }
  }
  .additionalInfo {
    width: 100%;
    padding-top: 80px;
    padding-bottom: 80px;
    @media #{$breakpoint-below-tablet} {
      padding-left: 20px;
      width: 92%;
    }
    .additionalInfoHeadline {
      color: #626365;
      font-family: $National2Regular;
      font-weight: 600;
      size: 12px;
      line-height: 18px;
      margin-bottom: 7px
    }
    .link-terms {
      color: #626365;
      font-family: $National2Regular;
      size: 12px;
      line-height: 18px;
      font-weight: 400;
      text-decoration: underline;
    }
    .link-faq {
      color: #626365;
      font-family: $National2Regular;
      size: 12px;
      line-height: 18px;
      font-weight: 400;
      text-decoration: underline;
      padding-left: 5px;
    }
    .additonalText {
      color: #626365;
      font-family: $National2Regular;
      size: 12px;
      line-height: 18px;
      font-weight: 400;
    }
  }
  .goToPaymentButton {
    color: #fff;
    width: 324px;
    @media #{$breakpoint-below-tablet} {
      width: 95%;
    }
  }

  .goToPaymentButton-disabled {
    background-color: #dbdbdb !important;
    color: #626365;
    pointer-events: none;
    width: 324px;
    font-weight: 600;
    @media #{$breakpoint-below-tablet} {
      width: 95%;
    }
  }
  .add-card-buton {
    padding-left: 300px;
    padding-top: 20px;
    @media #{$breakpoint-below-tablet} {
      padding-left: 19%;
    }
  }
  .go-to-payment-button-container {
    padding-top: 20px;
    padding-left: 250px;
    @media #{$breakpoint-below-tablet} {
      padding-left: 1%;
    }
  }
  .footerIcon {
    color: #202124;
    margin-left: 10px;
    margin-bottom: 3px
  }
  .gift-card-summary {
    margin-left: 190px;
    margin-right: 190px;
    border-bottom: 2px solid black;
    border-top: 2px solid black;
    margin-top: 30px;
    @media #{$breakpoint-below-tablet} {
      margin-left: 10px;
      margin-right: 15px
    }
    .global-accordian-header {
      font-weight: 700;
      font-family: $National2Condensed;
      font-size: 18px;
      line-height: 18px;
      letter-spacing: 0.5px;
      padding-top: 15px;
      padding-bottom: 15px;
      color: black;
    }
    .total-amount{
      font-weight: 700;
    }
    .ItemPrice{
      padding-right: 1px;
      letter-spacing: 0.5px;
      padding-top: 5px;
    }
    .global-accordian-arrow{
      width: 27px;
      height: 11px
    }
    .giftCardDescription {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      margin-top: 15px;
      margin-top: 8px;
    }
    .total-header {
      font-weight: 700px;
      font-family: $National2Condensed;
      font-size: 18px;
      line-height: 18px;
      letter-spacing: 0.5px;
      padding-left: 5px;
      @media #{$breakpoint-below-tablet} {
        padding-left: 8px;
      }
    }
    .number{
      @media #{$breakpoint-below-tablet} {
        margin-left: 6px;
      }
    }
    .giftCardName {
      font-family: $National2Medium;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #202124;
      padding-left:10px;
    }
  }
  .gift-card-summary-safari {
    .global-accordian-header-container{
      @media #{$breakpoint-below-tablet} {
        padding-left: 6px;
      }
    }
  }
}
